import React from 'react'
import '../App.css';
import './Header.css'
import logo from '../images/x color  03 1.svg'


const Header = () => {
  return (
      <header  style={{backgroundColor:"#707070"}} >
          <nav className="navbar navbar-expand-lg bg-body-tertiary" style={{backgroundColor:"#040404", padding:"0px"}}>
              <div className="container-fluid" style={{backgroundColor:"#040404", height:"48px", padding:"0px 156px", color:"white"}}>
                  <a className="navbar-brand" href="https://indexx.ai/">
                    <img src={logo} alt="" />
                  </a>
                  <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                      <span className="navbar-toggler-icon"></span>
                  </button>
                  <div className="collapse navbar-collapse" id="navbarSupportedContent">
                      <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                          <li className="nav-item">
                              <a className="nav-link" aria-current="page" href="/#crypto-cur">Cryptocurrency</a>
                          </li>
                          <li className="nav-item">
                              <a className="nav-link" href="/#fiat-cur">Fiat Currency</a>
                          </li>
                          <li className="nav-item">
                          <a className="nav-link" href="/features">Features</a>

                              {/* <Link to={'/features'}>  Features </Link> */}
                          </li>
                          <li className="nav-item">
                              <a className="nav-link" href="https://wallet2.indexx.ai/wallets/crypto-wallet">Wallet</a>
                          </li>
                      </ul>
                      <button className="d-flex button" type="submit"><a className="nav-link" href="https://xnftmarketplace.indexx.ai/">Buy XNFT</a></button>
                  </div>
              </div>
          </nav>
      </header>
  )
}

export default Header