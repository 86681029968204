import React from "react";
import "./Footer.css";
import logo from "../images/xnft all white 1.svg";
import "@fontsource/inter"; // Defaults to weight 400
import coins from "../images/Group 966 1.svg";
const Footer = () => {
  return (
    <div
      class="position-relative"
      style={{ backgroundColor: "#040404", color: "white" }}
    >
      <div>
        <img src={coins} alt="" className="responsive coins" />
      </div>
      <div className="" style={{ height: "237px" }}>
        <div style={{ alignContent: "center", display: "grid" }}>
          <img
            src={logo}
            alt=""
            style={{ marginTop: "44px", marginBottom: "20px" }}
          />
        </div>
        <ul style={{ color: "white", listStyleType: "none", fontSize:"20px" }}>
          <li>
            <a className="nav-link" aria-current="page" href="#crypto-cur">
              Cryptocurrency
            </a>
          </li>
          <li>
            <a className="nav-link" href="#fiat-cur">
              Fiat currency
            </a>
          </li>
          <li>
            <a
              className="nav-link"
              href="https://wallet2.indexx.ai/wallets/crypto-wallet"
            >
              Wallet
            </a>
          </li>
        </ul>
      </div>
      <div
        style={{
          backgroundColor: "#040404",
          color: "white",
          height: "36px",
          alignContent: "center",
          display: "grid",
        }}
      >
        Copyright © 2023 All Rights Reserved.
      </div>
    </div>
  );
};

export default Footer;
